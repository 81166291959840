import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify
({
  theme:
  {
    dark: false,
    themes:
    {
      dark:
      {
        primary: colors.green.darken2,
        accent: colors.lightGreen,
        warning: colors.orange.darken1,
      },
      light:
      {
        primary: colors.green.darken2,
        accent: colors.lightGreen,
        warning: colors.orange.darken1,
      },
    },
  }
});