<template lang='pug'>
v-card(
  dark
)
  v-card-title
    | Getting Started
  v-stepper(
    vertical
    flat
  )
    v-stepper-step(
      step='1'
      editable
    )
      | Step 1: Create Your Account
    v-stepper-content(
      step='1'
    )
      TheUserIcon(
        v-slot='{ isAnonymous, updateProp }'
      )
        v-btn(
          @click='updateProp(true)'
          color='primary'
          depressed
        )
          | Sign up - It's free!
    v-stepper-step(
      step='2'
      editable
    )
      | Step 2: Set Your Goals
    v-stepper-content(
      step='2'
    )
      v-btn(
        to='/daily-nutrition-goals'
        color='primary'
        depressed
      )
        | Goals
    v-stepper-step(
      step='3'
      editable
    )
      | Step 3: Log Your Food and Drinks
    v-stepper-content(
      step='3'
    )
      v-btn(
        :to='{ name: "FoodsSearchRedirect" }'
        color='primary'
        depressed
      )
        | Add food to diary
</template>

<script>
import TheUserIcon from '@thomasphan/vue-components/src/components/TheUserIcon.vue';

export default
{
  components:
  {
    TheUserIcon,
  },
};
</script>