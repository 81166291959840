<template lang='pug'>
v-hover(
  v-slot='{ hover }'
)
  slot(
    :disabled='disabled'
    :hover='hover'
    :loading='loading'
    :loadingUpdate='loadingUpdate'
  )
    v-btn(
      :disabled='disabled'
      :loading='loading'
      @click='loadingUpdate(true)'
      color='primary'
      depressed
    )
      v-icon(
        left
      )
        template(
          v-if='hover'
        )
          | mdi-check
        template(
          v-else
        )
          | mdi-check-outline
      slot(
        name='label'
      )
        | Okay
</template>

<script>
import { useEventToProp } from '@thomasphan/vue-components/src/functions/useEventToProp';

const setup = (props, ctx) =>
{
  const {
    prop: loading,
    propUpdate: _loadingUpdate,
  } = useEventToProp();

  const loadingUpdate = prop =>
  {
    _loadingUpdate(prop);

    ctx.emit('loading');

    return prop;
  };

  return {
    loading,
    loadingUpdate,
  };
};

export default
{
  props:
  {
    disabled: { required: true }
  },
  setup,
};
</script>