<template lang='pug'>
div
  v-dialog(
    :value='loading'
    fullscreen
    no-click-animation
    persistent
    transition='false'
  )
    v-card(
      flat
      loading
      tile
    )
      v-card-title
        IsAnonymous
          template(
            v-slot:isAnonymous
          )
            | Starting in guest mode
          template(
            v-slot:isNotAnonymous
          )
            | Loading
      v-card-subtitle
        | Please wait...
      v-card-text(
        v-if='VUE_APP_SITE_NAME === "EATANDTRACK"'
      )
        IsAnonymous
          template(
            v-slot:isAnonymous
          )
            v-card(
              outlined
              flat
            )
              v-card-title
                | Welcome to EatAndTrack.com!
              v-card-text
                | As a guest, you have full access to explore and experience our app's fantastic features for demo purposes. However, to fully enjoy the benefits and unlock the complete range of functionalities, we encourage you to sign up for an account.
  slot(
    v-if='shouldRenderSlot'
    :start='() => loadingUpdate(true)'
    :stop='() => loadingUpdate(false)'
  )
</template>

<script>
import IsAnonymous from '@thomasphan/vue-components/src/components/IsAnonymous.vue';

import { useEventToProp } from '@thomasphan/vue-components/src/functions/useEventToProp';

const setup = () =>
{
  const {
    prop: loading,
    propUpdate: loadingUpdate,
  } = useEventToProp();

  return {
    loading,
    loadingUpdate,
  };
};

function mounted()
{
  this.shouldRenderSlot = true;
}

export default
{
  components:
  {
    IsAnonymous,
  },
  data()
  {
    return {
      shouldRenderSlot: false,
      VUE_APP_SITE_NAME: process.env.VUE_APP_SITE_NAME,
    };
  },
  mounted,
  setup,
};
</script>